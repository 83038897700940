const allColours = [
  { fill: "#2196F3", stroke: "#1D83D4" },
  { fill: "#895ECC", stroke: "#7751B3" },
  { fill: "#D333BE", stroke: "#B729A4" },
  { fill: "#FFA828", stroke: "#E09322" },
  { fill: "#FFE928", stroke: "#E0CD23" },
  { fill: "#00B8DA", stroke: "#03A1BE" },
  { fill: "#F0142F", stroke: "#D4132B" },
  { fill: "#FF761B", stroke: "#D96315" },
  { fill: "#15A251", stroke: "#0F793C" },
  { fill: "#74C05E", stroke: "#5E9E4C" },
];

const categoryColours = {
  Break: { fill: "#F0142F", stroke: "#D4132B" },
  "Break with data quality issues": { fill: "#FF761B", stroke: "#D96315" },
  Match: { fill: "#15A251", stroke: "#0F793C" },
  "Match with data quality issues": { fill: "#74C05E", stroke: "#5E9E4C" },
};

const recStatusColours = {
  Open: { fill: "#2196F3", stroke: "#1D83D4" },
  Pending: { fill: "#FF761B", stroke: "#D96315" },
  Approved: { fill: "#15A251", stroke: "#0F793C" },
};

const updateColour = (name, seriesObj, colourProfile, selected) => {
  seriesObj.fill = colourProfile.fill;
  if (name === selected) {
    seriesObj.stroke = colourProfile.stroke;
    seriesObj.strokeWidth = 5;
  }
};

const filterTableOnClick = (gridRef, name, value, type = "text") => {
  let filterModel = gridRef.current.api.getFilterModel();
  if (value === null) {
    delete filterModel[name];
  } else {
    filterModel[name] = setFilterByType(value, type);
  }
  setTimeout(() => {
    gridRef.current.api.setFilterModel(filterModel);
  }, 0);
};

const setFilterByType = (value, type) => {
  let filter = {
    filterType: type,
    type: "equals",
    filter: value,
  };
  if (type === "set") {
    filter = {
      filterType: type,
      values: [value],
    };
  }

  return filter;
};

export { allColours, categoryColours, updateColour, filterTableOnClick, recStatusColours };
